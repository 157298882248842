.horizontal-slide-from-right-to-left {
  animation: 10s linear infinite horizontal-slide-from-right-to-left;
}

.horizontal-slide-from-left-to-right {
  animation: 10s linear infinite horizontal-slide-from-left-to-right;
}

@keyframes horizontal-slide-from-right-to-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

@keyframes horizontal-slide-from-left-to-right {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0);
  }
}

.sticky-menu.nav-hidden {
  animation: .9s fadeInDown;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes rotate-360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-360 {
  animation: 20s linear infinite rotate-360;
}

input:checked ~ .toggle_dot {
  transform: translateX(100%);
}
/*# sourceMappingURL=index.9be95568.css.map */
